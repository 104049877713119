<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
<div class="container mt-6">
    <div class="col" :style="{display:'flex',flexDirection:'column'}">
    <!--BIO-DATA-->
        <v-alert
        dense
        outlined
        :color="color1"
        type="info">
        Customer Bio-Data
      </v-alert>
      <v-avatar size="80">
       <v-img :src="test_image"></v-img>
      </v-avatar>

      <span class="mt-3 mb-3">
        <fai icon="server"/>&nbsp;
        <v-btn @click="viewAllSellerProducts" solo rounded color="primary">Seller Products</v-btn>
      </span><br><br>

      <span class="mt-3">
        <fai icon="user-circle"/>&nbsp;
        <v-chip outlined>Full Name</v-chip>&nbsp;{{current_user.firstname}}&nbsp;{{current_user.middlename}}&nbsp;{{current_user.lastname}}
      </span>
      
      <span class="mt-3">
        <fai icon="envelope-open"/>&nbsp;
        <v-chip outlined>Email</v-chip>&nbsp;{{current_user.email}}
      </span>

      <span class="mt-3 mb-3">
        <fai icon="phone"/>&nbsp;
        <v-chip outlined>Mobile</v-chip>&nbsp;{{current_user.phone}}
      </span>

      <!--WALLET-DATA-->
        <v-alert
        dense
        outlined
        :color="color1"
        type="info">
        Financial Data
      </v-alert>
      <span class="mt-3">
        <fai icon="credit-card"/>&nbsp;
        <v-chip outlined>Wallet ID&nbsp;</v-chip>{{current_user.wallet_id}}
      </span>

      <span class="mt-3">
        <fai icon="credit-card"/>&nbsp;
        <v-chip outlined>Total Balance</v-chip>
        &nbsp;{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(current_user.balance)}}
      </span>

      <span class="mt-3 caption" v-for="(item,i) in paymentMethods" :key="i">
        <fai icon="bank"/>&nbsp;
        <v-chip outlined>Payment Method &nbsp;({{parseInt(i)+1}})</v-chip>
        <div><strong>Bank Name:&nbsp;</strong>{{item.bank_name}}</div>
        <div><strong>Account Number:&nbsp;</strong>{{item.account_number}}</div>
        <div><strong>Account Name:&nbsp;</strong>{{item.account_name}}</div>
      </span>
<!--
      <div class="emailBox mt-2">
        <strong>Send Mail</strong>
        <v-text-field placeholder="subject" solo text dense></v-text-field>
        <v-textarea height="80px" solo text placeholder="mail content"></v-textarea>
        <v-btn :color="fill" dark text>Send&nbsp;<fai icon="envelope-open"/></v-btn>
      </div>
-->
  
    </div>

    <div class="col">
    <!--BIO-DATA-->
        <v-alert
        dense
        outlined
        :color="color1"
        type="info">
        Customer Account State
      </v-alert>
    <v-switch
        color="primary"
        value
        @change='updateAccountStatus'
        :loading="statusLoader"
        :input-value="(current_user.status===1)?true:false">
        <template v-slot:label>
        Activation Status
        </template>
    </v-switch>

    <v-switch
        color="primary"
        value
        @change='updateSpecialSeller'
        :loading="specialSellerLoader"
        :input-value="(current_user.special_seller===1)?true:false">
        <template v-slot:label>
        Special Seller Status
        </template>
    </v-switch>

    <span class="mt-3 mb-3">
      <div>
        <fai icon="address-card"/>&nbsp;
        <v-chip outlined>NIN</v-chip>&nbsp;{{current_user.nin}}</div>
      <div><br/>
        <fai icon="address-card"/>&nbsp;
        <v-chip outlined>CAC</v-chip>&nbsp;{{current_user.cac}}
        </div>
      
      </span>
        <v-switch
        color="primary"
        value
        @change='updateNiNStatus'
        :loading="ninStatusLoader"
        :input-value="(current_user.nin_verified===1 && current_user.cac_verified===1)?true:false">
        <template v-slot:label>
        Verify NIN/CAC
        </template>
        </v-switch>
        
        <v-switch
        color="warning"
        value
        @change='wrongNiNStatus'
        :loading="ninWrongLoader"
        :input-value="(current_user.nin_verified===2)?true:false">
        <template v-slot:label>
        Notify Wrong NIN/CAC
        </template>
        </v-switch>

        <!--shop-DATA-->
        <v-alert
        dense
        outlined
        :color="color1"
        type="info">
        Shop Information
      </v-alert>

      <span class="mt-3 mb-3">
        <fai icon="angle-double-right"/>&nbsp;
        <v-chip outlined>Shop Name</v-chip>&nbsp;{{current_user.shop_name}}
      </span><br><br>

      <span class="mt-3 mb-1">
        <fai icon="angle-double-right"/>&nbsp;
        <v-chip outlined>Shop Description</v-chip>
        <p>{{current_user.shop_description}}</p>
      </span>

      <span class="mt-3 mb-1">
        <fai icon="angle-double-right"/>&nbsp;
        <v-chip outlined>Shop Logo&nbsp;</v-chip><br/>
        <div :style="{display:'flex',justifyContent:'center',padding:'1em'}">
        <v-card width="250" class="mt-2" height="180">
            <v-img :style="{height:'100%'}" :src="$store.getters.get_api+'fetchShopImage/'+current_user.shop_logo"></v-img>
        </v-card>
        </div>
      </span>
    </div>
</div>


<v-overlay v-show="overlay">
<v-progress-circular indeterminate v-if="progress"></v-progress-circular>
<v-alert type="primary" :value="true" v-if="network_error">
    <template v-slot:default>
    <v-card dark>
        <v-card-text>
            {{message}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
            <v-btn rounded>Retry</v-btn>
        </v-card-actions>
    </v-card>
    </template>
</v-alert>
</v-overlay>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>


      <v-navigation-drawer
        v-model="productsd"
        absolute
        right
        width="400px"
        class="pa-2"
        color="#282c2e"
      >
        <div><v-btn @click.stop="productsd =!productsd" fab icon dark :color="fill"><fai icon="close"/>
        </v-btn>

        <div class="d-flex justify-center">
          <v-btn @click.stop="loadPreviousProducts" small class="mx-1">Previous</v-btn>
          <v-btn @click.stop="loadNextProducts" small>Next</v-btn>
        </div>

        </div>

        <div><v-progress-linear indeterminate v-show="progressx"></v-progress-linear></div>

        <div class="products">
            <!--<div><v-progress-linear indeterminate v-show="progressx"></v-progress-linear></div>-->
                <v-card :style="{borderRadius:'10px',overflowY:'hidden'}" class="product pb-2" v-for="(item,index) in products" :key="index">

                    <v-btn dark :color="fill" @click.stop="productInfo(index)" class="more_data_icon"  small fab><fai icon="ellipsis-v"/></v-btn>

                    <v-chip dark :color="fill" class="price_data_icon">
                      {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.price)}}
                    </v-chip>

                    <v-btn dark v-if="item.viewable===1" :color="fill" @click.stop="toggleViewable(index)" class="view_data_icon" small><fai icon="eye-slash"/></v-btn>

                    <v-btn v-if="item.viewable!==1" icon :color="fill" @click.stop="toggleViewable(index)" class="non_viewable_data_icon"  small fab><fai :style="{fontSize:'50px'}" icon="close"/></v-btn>

                    <v-img  class="img" :src="$store.getters.get_api+'fetchProductPic/'+item.preview" height="150"></v-img>

                    <v-card-text :style="{height:'100%',textOverflow:'ellipsis',overflowY:'hidden'}">
                        {{item.product_name}}
                    </v-card-text>
            
                </v-card>
        </div>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            productsd:false,
            overlay:false,
            drawer: false,
            current_page:1,
            total_products:0,
            selectedMenu:'Home',
            progressx:false,
            sideMenu:[
                
            ],
            test_image:require("../assets/1.jpg"),
            theme:'#f44336',
            fill:'#f44336',
            color1: '#f44336' ,   //'#cfd8dc',
            progress:true,
            network_error:false,
            current_user:{},
            message:'There was a network error',
            balance_data:{},
            //UPDATE ACCOUNT STATUS
            statusLoader:undefined,
            specialSellerLoader:false,
            //NIN INFORMATION
            ninStatusLoader:undefined,
            ninWrongLoader:undefined,
            paymentMethods:[],
            products:[],
            /**
             * TEST DATA
             */
            
        }
    },
    components:{
  
    },
    methods:{
    toggleViewable(index){
      this.progressx=true;
      this.axios({
        url:this.$store.getters.get_api+'toggleViewable/'+this.products[index].id,
        method:'get',
        headers:{
        "Access-Control-Allow-Origin":"*",
        'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        this.progressx=false;
        const data = res.data;
        if(!data.error){
          this.products[index].viewable=data.message;
        }
      }).catch((error)=>{
        this.progressx=false;
        return error;
      })
    },
    productInfo(index){
            this.products=this.products[index];
            this.$router.push('/productInformation/'+this.products.slog);
        },
    viewAllSellerProducts(){
      this.productsd=true;
      this.loadProductsOfSeller(1);
    },
    loadPreviousProducts(){
            if(this.current_page>1){
                this.current_page -=1;
                this.loadProductsOfSeller(this.current_page);
            }
        },
    loadNextProducts(){
        if(this.current_page<this.total_products){
            this.current_page +=1;
            this.loadProductsOfSeller(this.current_page);
        }
    },
    loadProductsOfSeller(page){
      this.progressx=true;
      this.axios({
        url:this.$store.getters.get_api+'fetchUserProductsAdmin/'+this.current_user.id+'/'+page,
        method:'get',
        headers:{
        "Access-Control-Allow-Origin":"*",
       'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        //console.log(data)
        if(!data.error){
          this.products=data.message.data;
          this.total_products=data.message.meta.total;
         // console.log(this.products)
          this.products.forEach( function(element) {
              element.preview = element.images.split(":")[0];
          });
        }
        this.progressx=false;
      }).catch((error)=>{
        this.loadProductsOfSeller(page);
        return error;
      })
    },
    updateSpecialSeller(){
      this.specialSellerLoader=true;
      const f = new FormData();
      f.append('user',this.current_user.id);
      f.append('value',(this.current_user.special_seller===1)?0:1);
      this.axios({
                url:this.$store.getters.get_api+'specialSellerConsideration/',
                method:'post',
                data:f,
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.specialSellerLoader=false;
                }
            }).catch((error)=>{
                return error
            })
    },
     updateAccountStatus(){
            this.statusLoader='success';
            this.axios({
                url:this.$store.getters.get_api+'toggleAccountStatus/'+this.current_user.id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.statusLoader=undefined;
                }
            }).catch((error)=>{
                return error
            })
        },
            wrongNiNStatus(){
            this.$store.dispatch('openProgress');
            this.axios({
                url:this.$store.getters.get_api+'wrongNiNStatus/'+this.current_user.id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.$store.dispatch('showMessage',{message:'Wrong NIN/CAC notification sent', type:'SUCCESS'})
                }
            }).catch((error)=>{
              this.$store.dispatch('showMessage',{message:'Network error', type:'ERROR'})
                return error
            })
        },
        updateNiNStatus(){
            this.$store.dispatch('openProgress');
            this.axios({
                url:this.$store.getters.get_api+'toggleNiNStatus/'+this.current_user.id,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
            }).then((res)=>{
                const data = res.data
                if(!data.error){
                    this.$store.dispatch('showMessage',{message:'NIN/CAC Status updated', type:'SUCCESS'})
                }
            }).catch((error)=>{
              this.$store.dispatch('showMessage',{message:'NIN/CAC updated failed', type:'ERROR'})
                return error
            })
        },
        loadBalanceInformation(){

        },
        loadUserData(){
            const user = this.$route.params.id;
            console.log(user)
            this.network_error=false;
            this.axios({
                url: this.$store.getters.get_api+'fetchUserDataAdmin/'+user,
                method:'get',
                headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
                }
            }).then((response)=>{
                const data = response.data;
                console.log(data)
                if(!data.error){
                    this.current_user = data.message;
                    this.paymentMethods = data.paymentMethods
                    this.progress=false;
                    this.overlay=false
                }
            }).catch((error)=>{
                this.progress=false
                this.network_error=true
                return error
            })
        },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
       this.loadUserData();
        //this.updateAccountStatus();
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
@font-face {
font-family: "ridge";
src: url(../assets/Ridge5-Bold.otf) format("opentype"),
/*url("CustomFont.otf") format("opentype"),
url("CustomFont.svg#filename") format("svg");*/
}
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#e0e0e0;
}
.icon-1{
    font-size: 50px;
}
.img{
    border-top-left-radius: 10px;
    border-top-right-radius:10px;
}
.product{
    position:relative;
}
.products{
    display: flex;
    justify-content: space-between;
    flex-flow:row wrap;
}
@media screen and (max-width:350px){
 .product{
        width:180px;
        height:250px;
        margin:3px;
    }
 .products{
  justify-content: center;
  height:420px;
  overflow-y: scroll;
 }
}

@media screen and (min-width:600px){
  .emailBox{
    width:350px;
  }
   .product{
        width: 170px;
        margin:2px;
        height:250px;
        /*overflow-y: scroll;*/
    }
    .products{
        max-width:500px;
        display: flex;
        flex-flow:row wrap;
        justify-content: center;
        height:500px;
        overflow-y: scroll;
    }
}
.more_data_icon{
    position:absolute;
   z-index: 5;
   right:3px;
}
.non_viewable_data_icon{
   position:absolute;
   z-index: 5;
}
.view_data_icon{
  position:absolute;
   z-index: 5;
}
.price_data_icon{
  position:absolute;
   z-index: 5;
   top:17%;
   right:2px;
}
.cont{
  font-family: 'ridge';
}

.product{
    position:relative;
}
</style>